import { SvgIcon } from "@mui/material";

const DuotoneChartSimpleIcon = (props) => {
    return (
        <SvgIcon viewBox="0 0 40 40" {...props}>
          {/* First Bar */}
          <rect x="5" y="16" rx="2" ry="2" width="9" height="18" fill="currentColor" />
          
          {/* Second Bar (Primary Layer) */}
          <rect x="16" y="6" rx="2" ry="2" width="9" height="28" fill="currentColor" opacity={0.6} />
          
          {/* Third Bar */}
          <rect x="27" y="12" rx="2" ry="2" width="9" height="22" fill="currentColor" />
        </SvgIcon>
      );
    };
export default DuotoneChartSimpleIcon;
