import { SvgIcon } from "@mui/material";

const DuotoneServerIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      {/* Outer box and horizontal separators, acting as the primary layer */}
      <rect
        x="2"
        y="2"
        rx="1"
        ry="1"
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2"
        y1="10"
        x2="22"
        y2="10"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="2"
        y1="14"
        x2="22"
        y2="14"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      
      {/* Internal details, acting as the secondary layer */}
      <rect
        x="4"
        y="4"
        rx="1"
        ry="1"
        width="4"
        height="2"
        fill="currentColor"
        opacity="0.4"
      />
      <rect
        x="4"
        y="12"
        rx="1"
        ry="1"
        width="4"
        height="2"
        fill="currentColor"
        opacity="0.4"
      />
      <rect
        x="4"
        y="18"
        rx="1"
        ry="1"
        width="4"
        height="2"
        fill="currentColor"
        opacity="0.4"
      />
    </SvgIcon>
  );
};

export default DuotoneServerIcon;
