import { SvgIcon } from "@mui/material";

const ModelIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      {/* Rear face of the cube, acting as the secondary layer */}
      <path
        d="M12 2l-10 5v10l10 5l10 -5V7l-10 -5z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        // opacity="0.4"
      />
      {/* Front face of the cube, acting as the primary layer */}
      <path
        d="M12 22V12l10 -5v10l-10 5z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {/* Bottom face of the cube, acting as the primary layer */}
      <path
        d="M2 7l10 5v10l-10-5V7z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};


export default ModelIcon;